import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Breadcrumbs,
  TextBox,
  VideoAndText,
  BannerLinks,
  BannerSmall,
  ProjectPagination
} from '../../components'

import boxes from '../../components/BannerLinks/DefaultBox'
import { generateBreadcrumbsLinks } from '../../utils/index'

import BannerImage from '../../assets/header.morf.jpg'
import image4 from '../../assets/bannerlinks/bannerbox.rauchmehl.jpg'
import image5 from '../../assets/bannerlinks/bannerbox.stadtamthall.png'


const TqSytemes = () => {
  const links = generateBreadcrumbsLinks('/projekte/morf', 'Morf')
  return (
    <Layout margin={false} color="black">
      <Helmet
        title="Morf"
        meta={[
          {
            name: 'description',
            content:
              'Verschönern und für Orientierung sorgen - seit 50 Jahren ist das die Mission der Schweizer Morf AG. Und damit ihre Arbeit im Bereich Straßen-Markierung und Signalisation mit Leichtigkeit funktioniert, entwickeln wir in enger Zusammenarbeit eine Web-Applikation für die gesamte Auftragsabwicklung.'
          },
          {
            name: 'keywords',
            content:
              'MORF holzweg, Projekt holzweg, Innsbruck, Webseite holzweg, Morf, MORF, Individual, Schweiz'
          },
          {
            property: 'og:description',
            content:
              'Verschönern und für Orientierung sorgen - seit 50 Jahren ist das die Mission der Schweizer Morf AG. Und damit ihre Arbeit im Bereich Straßen-Markierung und Signalisation mit Leichtigkeit funktioniert, entwickeln wir in enger Zusammenarbeit eine Web-Applikation für die gesamte Auftragsabwicklung.'
          },
          {
            property: 'og:image',
            content: 'https://www.holzweg.com/static/header.morf-e49dd868ec9337a9793fc469f9053733.jpg'
          }
        ]}
      />

      <BannerSmall image={BannerImage} title="Morf" />
      <Breadcrumbs
        links={links}
        backLinkAlias="Übersicht aller aktuellen Projekte"
      />
      <TextBox
        title={
          <div>
            MOMO - Ein Tool für alles
          </div>
        }
        text={
          <span>
            <p>Verschönern und für Orientierung sorgen - seit 50 Jahren ist das die Mission des schweizer Unternehmens Morf. Und damit ihre Arbeit im Bereich Straßen-Markierung und Signalisation mit Leichtigkeit funktioniert, entwickeln wir in enger Zusammenarbeit eine Web-Applikation für die gesamte Auftragsabwicklung.</p>
            <h2>Ein paar Highlights:</h2> 
            <p>In einer Desktop- und einer mobilen Version deckt Momo die folgenden Themenbereiche ab: Projektverwaltung, Kundenverwaltung, Angebotslegung, Einsatzdisponierung, Rechnungserstellung, Mitarbeiterverwaltung, Aufwandserhebung.</p>
            <ul>
              <li>Die mobile Web-App dient dazu, das Projekt bereits <b>direkt  beim  Kunden</b> planen zu können. Aufträge werden hinzugefügt, Bilder und GPS-Koordinaten gespeichert. </li>
              <li>Auftragsdurchführung: Mittels Einscannen von <b>QR-Codes</b>, den gespeicherten <b>GPS-Koordinaten</b> und der <b>Google  Maps  Navigation</b> finden Mitarbeitende zum Auftragsort. Durch die Speicherung der Strecken wird ebenso die Umsetzung der Markierungen erleichtert.</li>
              <li><b>Produktkataloge</b> verwalten</li>
              <li><b>Multifunktionelle  Datentabelle:</b> Aufklappbare Zeilen und Container, Spaltensortierung, fixierter Header, Inline-Editing, Pagination, Drag & Drop, gespeicherte User-Ansichten</li>
              <li>Keyboard-Steuerung für eine <b>effiziente</b> und <b>schnelle</b> Arbeitsweise</li>
              <li>Suche und Filterung mit <b>Autocomplete</b> und <b>Vorschaufenstern</b></li>
              <li>Editierbare Druckansichten und <b>CSV</b>-Importe und -Exporte </li>
              <li><b>Historie</b> von Eigenschaften</li>
              <li>User-<b>Rechte</b>management </li>
            </ul>
            <br />
            <h4>Technische Spezifikationen</h4>
            <p>Nach vielen Konzeptionsmeetings entwickelt Holzweg eine Web-Applikation für die gesamte Auftragsabwicklung.<br />
              SAP? Wird hier nicht mehr gebraucht!<br />
              Wir setzten auf API-basierte SPA-Anwendungen umgesetzt mit React, Node.js, Material-UI und GraphQL.<br />
              Typisierungsfehler werden effizient vermieden durch den Einsatz von TypeScript.
            </p>
            <h3>React, Node.js, Material-UI, GraphQL</h3>
            <br />
            <h2>Unternehmensprozesse digitalisieren? Aber wie?</h2>
            <p>
              Mit holzweg GmbH® natürlich. Wir bauen individuelle Software für Ihr Unternehmen. Wir passen die Software an Sie an, nicht umgekehrt! Klingt gut? Ist gut und schaut gut aus :)
            </p>
            </span>
        }
        buttons={[
            {link: '/individual-software', text: 'Individual-Software'}
        ]}
        />
      <VideoAndText
        YTvideo="7KRK-iy0Hps"
        headline="Präsentationsvideo"
        text="Da MOMO zu viel drauf hat, um im obenstehenden Text alles aufzulisten und durch das eigene Auge sowieso ein besserer Eindruck möglich ist, haben wir ein lässiges Video zusammengestellt. Dort findet sich ein erster Einblick über die Probleme, die Momo löst. Sound on!"
        subtext={
          <span>
            <br />
            Musik:<br />
          <br />
          Path to Innovation - soundcloud.com/fm_freemusic<br />
          Summer - Bensound<br />
          <br />
          Mockup designed by upklyak / Freepik
          </span>
        }
      />
      <ProjectPagination
        titleLeft={
          <span>Rauchmehl Website<br/>im neuen Gewand</span>
        }
        imageLeft={image4}
        leftLink="/projekte/rauchmehl"
        titleModsLeft={["black"]}
        titleRight="Stadtamt Hall Relaunch"
        imageRight={image5}
        rightLink="/projekte/stadtamthall"
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default TqSytemes
